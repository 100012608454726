#modal-pop-up-smarter-click .modal-content {
  border-radius: 10px !important;
}

#modal-pop-up-smarter-click * {
  color: black !important;
}

#modal-pop-up-smarter-click h1,
#modal-pop-up-smarter-click h2,
#modal-pop-up-smarter-click h3,
#modal-pop-up-smarter-click h4,
#modal-pop-up-smarter-click h5,
#modal-pop-up-smarter-click h6 {
  margin: 0 !important;
  line-height: 1.3;
}

#modal-pop-up-smarter-click h1 *,
#modal-pop-up-smarter-click h2 *,
#modal-pop-up-smarter-click h3 *,
#modal-pop-up-smarter-click h4 *,
#modal-pop-up-smarter-click h5 *,
#modal-pop-up-smarter-click h6 * {
  font-size: inherit !important;
}

#modal-pop-up-smarter-click h1 {
  font-size: 35px !important;
  text-align: center !important;
}

@media (max-width: 992px) {
  #modal-pop-up-smarter-click h1 {
    font-size: 26px !important;
  }
}

#modal-pop-up-smarter-click h2 {
  font-size: 25px !important;
}

@media (max-width: 992px) {
  #modal-pop-up-smarter-click h2 {
    font-size: 19px !important;
  }
}

#modal-pop-up-smarter-click .btn {
  color: white !important;
  padding: 15px 50px !important;
  font-weight: bold !important;
  border-radius: 5px !important;
  font-size: 20px !important;
}

@media (max-width: 992px) {
  #modal-pop-up-smarter-click .btn {
    font-size: 16px !important;
  }
}

#modal-pop-up-smarter-click .btn.btn-info {
  background-color: #3697FB !important;
}

#modal-pop-up-smarter-click .btn.btn-success {
  background-color: #83E24F !important;
}

#modal-pop-up-smarter-click input[type="text"] {
  text-align: center;
  border-radius: 5px;
  padding: 25px;
}

@media (max-width: 992px) {
  #modal-pop-up-smarter-click input[type="text"] {
    font-size: 16px !important;
  }
}

#modal-pop-up-smarter-click .error {
  border-color: #f44336 !important;
}

#modal-pop-up-smarter-click .close-pop-up-smarter-click {
  float: none !important;
  font-size: 20px !important;
  cursor: pointer;
}

@media (max-width: 992px) {
  #modal-pop-up-smarter-click .close-pop-up-smarter-click {
    font-size: 16px !important;
  }
}


