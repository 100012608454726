@import "../shared/responsive-vars";

#modal-pop-up-smarter-click {
  .modal-content {
    border-radius: 10px !important;
  }

  * {
    color: black !important;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 !important;
    line-height: 1.3;
    * {
      font-size: inherit !important;
    }
  }

  h1 {
    font-size: 35px !important;
    text-align: center !important;
    @media (max-width: $break-md) {
      font-size: 26px !important;
    }
  }

  h2 {
    font-size: 25px !important;
    @media (max-width: $break-md) {
      font-size: 19px !important;
    }
  }

  .btn {
    color: white !important;
    padding: 15px 50px !important;
    font-weight: bold !important;
    border-radius: 5px !important;
    font-size: 20px !important;
    @media (max-width: $break-md) {
      font-size: 16px !important;
    }

    &.btn-info {
      background-color: #3697FB !important;
    }

    &.btn-success {
      background-color: #83E24F !important;
    }
  }

  input[type="text"] {
    text-align: center;
    border-radius: 5px;
    padding: 25px;
    @media (max-width: $break-md) {
      font-size: 16px !important;
    }
  }

  .error {
    border-color: #f44336!important;
  }

  .close-pop-up-smarter-click {
    float: none !important;
    font-size: 20px !important;
    @media (max-width: $break-md) {
      font-size: 16px !important;
    }
    cursor: pointer;
  }
}